//#region IMPORT

import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import "../../functions/extension.function";
import { Observable, of } from "rxjs";
import { ResponseModel } from "src/app/models/response.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { SessionService } from "../session.service";
import { HandshakeService } from "../handshake.service";
import { BaseauthorizedGuard } from "./baseauthorized.guard";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class GeneralAuthorizedGuardService extends BaseauthorizedGuard implements CanActivate
{
	//#region DECLARATION

	public _stringDocumentTokenGuard: string = "";
	public _stringDownloadCategoryGuard: string = "";
	public _arrayStringURLGuard: Array<string> = [];

	//#endregion


	//#region CONSTRUCTOR

	constructor(protected _serviceHandshake: HandshakeService, protected _router: Router, protected _serviceSession: SessionService)
	{
		super(_serviceHandshake, _router, _serviceSession);
		this.getDocumentTokenAndCategoryFromURLParameter();
	}

	//#endregion


	//#region ACTIVATE

	canActivate(): Observable<boolean>
	{
		if (this._stringDownloadCategoryGuard.includes("download"))
		{
			try
			{
				let booleanSignIn: boolean = false;

				if (this.validateModelHandshake(this._modelHandshakeSignIn))
				{
					booleanSignIn = true;
				}
				else
				{
					this._modelHandshakeSignIn = this._serviceSession.getModelHandshakeSignInFromLocalStorage();

					if (this.validateModelHandshake(this._modelHandshakeSignIn))
					{
						if (this._modelHandshakeSignIn != null)
						{
							this._serviceSession.setModelHandshakeSignInToLocalStorage(this._modelHandshakeSignIn);

							booleanSignIn = true;
						}
						else
						{

						}
					}
					else
					{

					}
				}

				if (booleanSignIn)
				{
					const modelUserSession: UserSessionModel | null = this._serviceSession.getUserSession();
					if (modelUserSession != null)
					{
						if (modelUserSession.UserToken != null || modelUserSession.UserToken === undefined || modelUserSession.UserToken === "" )
						{
							return this.callRefreshTokenForUnderwriting();
						}
						else
						{
							this.signOutDownload(this._stringDownloadCategoryGuard);
							return of(false);
						}
					}
					else
					{
						this.signOutDownload(this._stringDownloadCategoryGuard);
						return of(false);
					}
				}
				else
				{
					const modelResponse: ResponseModel = new ResponseModel();
					modelResponse.setSessionExpired();

					this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { this.signOutDownload(this._stringDownloadCategoryGuard); });

					return of(false);
				}
			}
			catch (error: any)
			{
				const modelResponse: ResponseModel = new ResponseModel();
				modelResponse.setSessionExpired();
				modelResponse.MessageContent = error.error;

				this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { this.signOutDownload(this._stringDownloadCategoryGuard); });

				return of(false);
			}
		}
		else
		{
			try
			{
				let booleanSignIn: boolean = false;

				if (this.validateModelHandshake(this._modelHandshakeSignIn))
				{
					booleanSignIn = true;
				}
				else
				{
					this._modelHandshakeSignIn = this._serviceSession.getModelHandshakeSignInFromLocalStorage();

					if (this.validateModelHandshake(this._modelHandshakeSignIn))
					{
						if (this._modelHandshakeSignIn != null)
						{
							this._serviceSession.setModelHandshakeSignInToLocalStorage(this._modelHandshakeSignIn);

							booleanSignIn = true;
						}
						else
						{

						}
					}
					else
					{

					}
				}

				if (booleanSignIn)
				{
					const modelUserSession: UserSessionModel | null = this._serviceSession.getUserSession();
					if (modelUserSession != null)
					{
						if (modelUserSession.UserToken != null || modelUserSession.UserToken === undefined || modelUserSession.UserToken === "" )
						{
							return this.callRefreshTokenForUnderwriting();
						}
						else
						{
							this.signOut();
							return of(false);
						}
					}
					else
					{
						this.signOut();
						return of(false);
					}
				}
				else
				{
					const modelResponse: ResponseModel = new ResponseModel();
					modelResponse.setSessionExpired();

					this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { this.signOut(); });

					return of(false);
				}
			}
			catch (error: any)
			{
				const modelResponse: ResponseModel = new ResponseModel();
				modelResponse.setSessionExpired();
				modelResponse.MessageContent = error.error;

				this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { this.signOut(); });

				return of(false);
			}
		}
	}

	//#endregion


	//#region FUNCTION

	signOut(): void
	{
		this.clearSession();

		this._router.navigate(["signin"]);
	}

	signOutDownload(stringURL: string): void
	{
		this.clearSession();
		this._router.navigate(["signin", stringURL, this._stringDocumentTokenGuard], { queryParamsHandling: "preserve" });
	}

	//#endregion


	//#region GETTER

	getDocumentTokenAndCategoryFromURLParameter(): void
	{
		const stringURL: string = window.location.pathname;
		this._arrayStringURLGuard = stringURL.split("/");
		this._stringDocumentTokenGuard = this._arrayStringURLGuard[(this._arrayStringURLGuard.length - 1)];
		this._stringDownloadCategoryGuard = this._arrayStringURLGuard[(this._arrayStringURLGuard.length - 2)];
	}

	//#endregion
}

//#endregion