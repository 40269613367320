//#region IMPORT

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { UnderwritingModel } from "src/app/models/underwriting.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { PwaService } from "src/app/services/pwa.service";
import { SessionService } from "src/app/services/session.service";
import { UnderwritingService } from "src/app/services/underwriting.service";
import { StringConstant } from "src/app/constants/string.constant";
import { NumberConstant } from "src/app/constants/number.constant";
import { TableModel } from "src/app/models/bases/table.model";
import { ResponseModel } from "src/app/models/response.model";
import { AnomalyNotificationService } from "src/app/services/anomalynotification.service";
import { ENUM_LETTER_ROLE } from "../../constants/enum.constant";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-home",
		templateUrl: "./home.component.html",
		styleUrls: ["./home.component.sass"]
	}
)

//#endregion


//#region CLASS

export class HomeComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _booleanNavigation: boolean | null;
	public _booleanNavigationItem: boolean | null;

	public _booleanNavigationItem2: boolean | null;
	public _booleanNavigationItemDashboard: boolean | null;

	public _booleanNavigationItemForReimbursment: boolean | null;
	public _booleanNavigationItemForApproval: boolean | null;
	public _booleanNavigationItemRCSA: boolean | null;
	public _booleanNavigationItemDocumentRequest: boolean | null;
	public _booleanNavigationPROTECT: boolean | null;
	public _booleanNavigationEmaterai: boolean | null;
	public _booleanNavigationItemApproval: boolean | null;
	public _booleanMonitoring: boolean | null;
	public _booleanNavigationItemRAS: boolean | null;
	public _booleanNavigationItemGCGOnline: boolean | null;
	public _booleanNavigationCodeOfEthics: boolean | null;
	public _booleanNavigationAnnualDisclosure: boolean | null;
	public _booleanNavigationApuPPT: boolean | null;
	public _booleanNavigationBrinsTax: boolean | null;
	public _booleanNavigationBrinspro: boolean | null;

	public _enumLetterRole = ENUM_LETTER_ROLE;

	public _numberConstant = NumberConstant;
	public _numberAnomalyNotification: number;
	public _stringConstant = StringConstant;
	public _stringHeadOffice = StringConstant.STRING_FIELD_BRANCHCODE_HEADOFFICE;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceUnderwriting: UnderwritingService, serviceSession: SessionService, router: Router, public _servicePWA: PwaService, private _serviceAnomalyNotification: AnomalyNotificationService)
	{
		super(serviceSession, router);

		this._booleanNavigation = null;
		this._booleanNavigationItem = null;
		this._booleanNavigationItem2 = null;
		this._booleanNavigationItemDashboard = null;

		this._booleanNavigationItemForReimbursment = null;
		this._booleanNavigationItemForApproval = null;
		this._booleanNavigationItemRCSA = null;
		this._booleanNavigationItemDocumentRequest = null;
		this._booleanNavigationPROTECT = null;
		this._booleanNavigationEmaterai = null;
		this._booleanNavigationItemApproval = null;
		this._booleanMonitoring = null;
		this._booleanNavigationItemRAS = null;
		this._numberAnomalyNotification = 0;
		this._booleanNavigationItemGCGOnline = null;
		this._booleanNavigationCodeOfEthics = null;
		this._booleanNavigationAnnualDisclosure = null;
		this._booleanNavigationApuPPT = null;
		this._booleanNavigationBrinsTax = null;
		this._booleanNavigationBrinspro = null;
	}

	//#endregion


	//#region NAVIGATION

	ngOnInit(): void
	{
		const stringURL: string = this.getTokenFromURLParamter();

		if (stringURL === "home")
		{
			if (this._modelUserSignIn.checkUserGroupUnderwritingFire())
			{
				this._router.navigate(["home", "dashboard", "fireproduct"]);
			}
			else if (this._modelUserSignIn.checkUserGroupUnderwritingMarineCargo())
			{
				this._router.navigate(["home", "dashboard", "cargoproduct"]);
			}
			else
			{
				this._router.navigate(["home", "dashboard"]);
			}
		}
	}

	//#endregion


	//#region FUNCTION

	toggleNavigation(): void
	{
		this._booleanNavigation = !this._booleanNavigation;
		this._booleanNavigationItem = false;
		this._booleanNavigationItem2 = false;
		this._booleanNavigationItemDashboard = false;
		this._booleanNavigationItemForReimbursment = false;
		this._booleanNavigationItemForApproval = false;
		this._booleanNavigationItemRCSA = false;
		this._booleanNavigationItemDocumentRequest = false;
		this._booleanNavigationPROTECT = false;
		this._booleanNavigationEmaterai = false;
		this._booleanNavigationItemApproval = false;
		this._booleanMonitoring = false;
		this._booleanNavigationItemGCGOnline = false;
		this._booleanNavigationCodeOfEthics = false;
		this._booleanNavigationAnnualDisclosure = false;
		this._booleanNavigationApuPPT = false;
	}

	toggleNavItem(): void
	{
		this._booleanNavigationItem = false;
		this._booleanNavigationPROTECT = false;
	}

	//#endregion


	//#region WEB SERVICE

	callSignOut(): void
	{
		const componentCurrent: HomeComponent = this;
		const modelUserSession: UserSessionModel | null = this._serviceSession.getUserSession();

		if (modelUserSession != null)
		{
			this._serviceUnderwriting.signOut
			(
				{
					success(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
							{
								componentCurrent.signOut();
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
							}
						});
					},
					fail(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSignOut(); });
					},
					signOut(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
					}
				}, new UnderwritingModel()
			);
		}
		else
		{
			componentCurrent.signOut();
		}
	}

	public callSelectAnomalyNotificationCount(componentCurrent: this): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(this._numberConstant.NUMBER_COMPARE_VALUE_ONE);

		const modelTable: TableModel = new TableModel();

		this._serviceAnomalyNotification.selectAnomalyNotificationCount
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data != null || modelResponse.Data !== undefined)
					{
						componentCurrent._numberAnomalyNotification = Number(modelResponse.Data);
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
				}
				else
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectAnomalyNotificationCount(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	//#endregion


	//#region NAVIGATION

	goToDashboard(): void
	{
		this._router.navigate(["home", "dashboard"]);
		this.toggleNavigation();
	}

	goToDashboardStamp(): void
	{
		this._router.navigate(["home", "dashboard", "stamp"]);
		this.toggleNavigation();
	}

	goToDashboardEmaterai(): void
	{
		this._router.navigate(["home", "dashboard", "ematerai"]);
		this.toggleNavigation();
	}

	goToProductInformation(): void
	{
		this._router.navigate(["home", "productinformation"]);
		this.toggleNavigation();
	}

	goToAbout(): void
	{
		this._router.navigate(["home", "about"]);
		this.toggleNavigation();
	}

	goToApprovalSppa(): void
	{
		this._router.navigate(["home", "approval", "sppa", "list"]);
		this.toggleNavigation();
	}

	goToDashboardFireProduct(): void
	{
		this._router.navigate(["home", "dashboard", "fireproduct"]);
		this.toggleNavigation();
	}

	goToDashboardCargoProduct(): void
	{
		this._router.navigate(["home", "dashboard", "cargoproduct"]);
		this.toggleNavigation();
	}

	goToApprovalPolicy(): void
	{
		this._router.navigate(["home", "approval", "policy", "list"]);
		this.toggleNavigation();
	}

	goToCertificate(): void
	{
		this._router.navigate(["home", "certificate", "list"]);
		this.toggleNavigation();
	}

	goToTicketReceived(): void
	{
		this._router.navigate(["home", "ticket", "received", "list"]);
		this.toggleNavigation();
	}

	goToTicketSent(): void
	{
		this._router.navigate(["home", "ticket", "sent", "list"]);
		this.toggleNavigation();
	}

	goToTicketReport(): void
	{
		this._router.navigate(["home", "ticket", "report"]);
		this.toggleNavigation();
	}

	goToTicketSatisfactionReport(): void
	{
		this._router.navigate(["home", "ticket", "satisfactionreport"]);
		this.toggleNavigation();
	}

	goToEmaterai(): void
	{
		this._router.navigate(["home", "ematerai", "list"]);

	}

	goToEmateraiForMaker(): void
	{
		this._router.navigate(["home", "ematerai", "maker", "list"]);
		this.toggleNavigation();
	}

	goToEmateraiForChecker(): void
	{
		this._router.navigate(["home", "ematerai", "checker", "list"]);
		this.toggleNavigation();
	}

	goToEmateraiForSigner(): void
	{
		this._router.navigate(["home", "ematerai", "signer", "list"]);
		this.toggleNavigation();
	}

	goToReimbursementGeneralChecker(): void
	{
		this._router.navigate(["home", "reimbursement" , "approval", "checker", "list"]);
		this.toggleNavigation();
	}

	goToReimbursementGeneralSigner(): void
	{
		this._router.navigate(["home", "reimbursement" , "approval", "signer", "list"]);
		this.toggleNavigation();
	}

	goToAssessment(): void
	{
		this._router.navigate(["home", "rcsa", "assessment", "list"]);
		this.toggleNavigation();
	}

	goToApproval(): void
	{
		this._router.navigate(["home", "rcsa", "approval", "list"]);
		this.toggleNavigation();
	}

	goToReport(): void
	{
		this._router.navigate(["home", "rcsa", "report", "list"]);
		this.toggleNavigation();
	}

	goToRiskIssueRequest(): void
	{
		this._router.navigate(["home", "rcsa", "riskissuerequest", "list"]);
		this.toggleNavigation();
	}

	goToDigitalizationChecker(): void
	{
		this._router.navigate(["home", "digitalization", "checker", "list"]);
		this.toggleNavigation();
	}

	goToDigitalizationMaker(): void
	{
		this._router.navigate(["home", "digitalization", "maker", "list"]);
		this.toggleNavigation();
	}

	goToDigitalizationSigner(): void
	{
		this._router.navigate(["home", "digitalization", "signer", "list"]);
		this.toggleNavigation();
	}
	goToPolicyRenewalMonitoring(): void
	{
		this._router.navigate(["home", "monitoring", "policyrenewal", "list"]);
		this.toggleNavigation();
	}
	goToPolicyRenewalApproval(): void
	{
		this._router.navigate(["home", "approval", "policyrenewal", "list"]);
		this.toggleNavigation();
	}

	goToAnomaly(): void
	{
		this._router.navigate(["home", "protect", "anomaly", "list"]);
		this.toggleNavigation();
	}

	goToAnomalyNotification(): void
	{
		this._router.navigate(["home", "protect", "anomaly", "notification"]);
		this.toggleNavigation();
	}

	goToMonitoringCargo(): void
	{
		this._router.navigate(["home", "monitoring", "cargoproduct", "list"]);
		this.toggleNavigation();
	}

	goToRASFollowUpPlan(): void
	{
		this._router.navigate(["home", "ras", "followupplan", "list"]);
		this.toggleNavigation();
	}

	goToRASDashboard(): void
	{
		this._router.navigate(["home", "ras", "dashboard", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineProfile(): void
	{
		this._router.navigate(["home", "gcgonline", "profile"]);
		this.toggleNavigation();
	}

	goToGCGOnlineGratification(): void
	{
		this._router.navigate(["home", "gcgonline", "gratification", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineIntegrityPact(): void
	{
		this._router.navigate(["home", "gcgonline", "integritypact", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineCodeOfEthics(): void
	{
		this._router.navigate(["home", "gcgonline", "codeofethics", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineCodeOfEthicsApproval(): void
	{
		if (this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGCHECKER))
		{
			this._router.navigate(["home", "gcgonline", "codeofethics", "checker", "list"]);
			this.toggleNavigation();
		}
		else if (this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGSIGNER))
		{
			this._router.navigate(["home", "gcgonline", "codeofethics", "signer", "list"]);
			this.toggleNavigation();
		}
		else
		{
		}
	}

	goToGCGOnlineAnnualDisclosure(): void
	{
		this._router.navigate(["home", "gcgonline", "annualdisclosure", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineAnnualDisclosureApproval(): void
	{
		if (this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGCHECKER))
		{
			this._router.navigate(["home", "gcgonline", "annualdisclosure", "checker", "list"]);
			this.toggleNavigation();
		}
		else if (this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGSIGNER))
		{
			this._router.navigate(["home", "gcgonline", "annualdisclosure", "signer", "list"]);
			this.toggleNavigation();
		}
		else
		{
		}
	}

	goToAPUPPTCustomer(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_APUPPT, StringConstant.STRING_PATH_ROUTING_CUSTOMERDATA]);
		this.toggleNavigation();
	}

	goToRiskBasedAssessment(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_APUPPT, StringConstant.STRING_PATH_ROUTING_RISKBASEDASSESSMENT]);
		this.toggleNavigation();
	}

	goToTransaction(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_APUPPT, StringConstant.STRING_PATH_ROUTING_TRANSACTION, StringConstant.STRING_PATH_ROUTING_LIST]);
		this.toggleNavigation();
	}

	goToTaxRegulation(): void
	{
		this._router.navigate(["home", "brinstax", "taxregulation", "list"]);
		this.toggleNavigation();
	}

	goToTaxReport(): void
	{
		this._router.navigate(["home", "brinstax", "taxreport", "list"]);
		this.toggleNavigation();
	}


	goProjectManagementDashboard(): void
	{
		this._router.navigate(["home", "brinspro", "dashboard"]);
		this.toggleNavigation();
	}

	goToProjectManagementProject(): void
	{
		this._router.navigate(["home", "brinspro", "project", "list"]);
		this.toggleNavigation();
	}

	goToProjectManagementDocument(): void
	{
		this._router.navigate(["home", "brinspro", "document", "list"]);
	}

	//#endregion


}

//#endregion